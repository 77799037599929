import React, { useState, useEffect } from "react";
import "./index.css";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import images from "../../../assets/images";
import { BsFillCalendar2EventFill } from "react-icons/bs";
import Contact from "../../../components/main/contact";
import Testimonial from "../../../components/main/testimonial";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/virtual";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const Destination18 = () => {
  const { t } = useTranslation("translations");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onWhatsApp = () => {
    const phoneNumber = "6281390070766";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div className="dest18">
      <Navbar />
      <div className="cont">
        <div className="bod">
          <div className="cov">
            <img src={images.L5D4N} alt="Dest 1" />
          </div>
          <div className="hea">
            <h1>{t("Semeru.M1.T")}</h1>
            {/* <p>
             In this package you will get amazing views such as active
             volcanoes, beautiful waterfalls and the diversity of places you
             visit.
           </p> */}
          </div>
          <div className="mai">
            <div className="left">
              <Card className="card">
                <div className="single-price">
                  <h5>{t("destination.PPPT")}</h5>
                  {/* <h4>Start From</h4> */}
                  <h4>Rp. 0</h4>
                </div>
                <div className="line"></div>
                <div className="custom-price">
                  <h5>{t("destination.PCPT")}</h5>
                  <h4>{t("destination.PCPD")}</h4>
                </div>
                <div
                  className="button-order"
                  type="button"
                  onClick={onWhatsApp}
                >
                  {t("button.order")}
                </div>
              </Card>
            </div>
            <div className="right">
              <div className="heade">
                <BsFillCalendar2EventFill className="ico" />
                <h2>{t("destination.PIT")}</h2>
              </div>
              <div className="bodi">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggle("1");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID1")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggle("2");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID2")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggle("3");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID3")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={() => {
                        toggle("4");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID4")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "5" ? "active" : ""}
                      onClick={() => {
                        toggle("5");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PIDI")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "6" ? "active" : ""}
                      onClick={() => {
                        toggle("6");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PIDE")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          {/* <h6 style={{ color: 'red' }}>Note:</h6>
             <h6 style={{ color: 'red' }}>
              - Return flight at night or stay one night in Suarabaya, we can
              change the itinerary to suit your flight schedule. You will get
              detailed the itinerary once you submit your flight time schedule
             </h6>
             <h6 style={{ color: 'red' }}>
              - Madakaripura Waterfall can be requested (depends on your flight
              schedule)
             </h6> */}
                          {/* <CardTitle>
              <h3 style={{ marginTop: '20px' }}>Day 1</h3>
             </CardTitle> */}
                          <CardTitle>
                            <h3>{t("destination.PID1")}</h3>
                          </CardTitle>
                          {/* <CardText>
              <ul>
               <li>
                12.00 - 12.30 WIB | Pick up on request (Hotel, Station, Airport)
               </li>
               <li>12.30 - 16.30 WIB | Lunch & Depart to hotel area Bromo</li>
               <li>
                16.30 - 16.30 WIB | Check in hotel and rest & dinner in area
                Bromo
               </li>
              </ul>
             </CardText> */}
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PID2")}</h3>
                          </CardTitle>
                          {/* <CardText>
              <ul>
               <li>02.30 - 03.30 WIB | Pick up jeep Bromo sunrise tour</li>
               <li>
                03.30 - 04.10 WIB | Waiting at the coffee shop at the sunrise
                point in Bromo area
               </li>
               <li>
                04.10 - 08.40 WIB | Penanjakan 1 / Kingkong Hill, Widodaren
                Hill, Sea Sand, Whispering Sand, Bromo Crater (Can walk or ride
                a horse), Teletubbies Hill
               </li>
               <li>
                08.40 - 11.30 WIB | Return to the hotel, breakfast & check out
                from hotel
               </li>
               <li>12.00 - 16.30 WIB | Depart to Hotel Bondowoso city</li>
               <li>16.30 - 16.30 | Check in Hotel, dinner, and rest</li>
              </ul>
             </CardText> */}
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PID3")}</h3>
                          </CardTitle>
                          {/* <CardText>
              <ul>
               <li>
                00.00 - 02.00 | Depart to Ijen Paltuding Post (With take away
                breakfast)
               </li>
               <li>
                02.00 - 03.30 | Start trekking to Ijen Peak (Climb in approx 1
                hour and 30 minutes)
               </li>
               <li>
                04.00 - 04.30 | Blue fire Ijen Crater (30 minutes blue flame
                tracking)
               </li>
               <li>
                04.30 - 06.45 | Relax and enjoy at Ijen Peak (Climb in approx 30
                minutes)
               </li>
               <li>07.00 - 07.45 | Return to parking area</li>
               <li>08.00 - 10.00 | Return to the hotel</li>
               <li>
                12.00 - 17.00 | Check out hotel and depart to Hotel in Malang
               </li>
               <li>17.30 - 18.30 | Check in hotel</li>
               <li>
                18.30 - 21.00 | Shopping in Malang city area, dinner, Town
                square Batu city
               </li>
               <li>21.00 - 21.00 | Return to the hotel and rest</li>
              </ul>
             </CardText> */}
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PID4")}</h3>
                          </CardTitle>
                          {/* <CardText>
              <ul>
               <li>07.00 - 08.00 | Breakfast and check out hotel</li>
               <li>09.00 - 10.00 | Apple garden</li>
               <li>
                10.30 - 12.00 | Coban rondo waterfall and Maze rondo waterfall
               </li>
               <li>
                12.00 - 15.30 | Paragliding hill, Wooden hill house, Sky park,
                lunch
               </li>
               <li>15.30 - 17.30 | Transportation museum</li>
               <li>18.00 - 19.30 | Dinner in Batu city</li>
               <li>19.30 - 21.30 | Depart to Surabaya city</li>
               <li>21.30 - 21.30 | Finish</li>
              </ul>
             </CardText> */}
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PIDI")}</h3>
                          </CardTitle>
                          {/* <CardText>
              <ul>
               <li>Transport</li>
               <li>Driver</li>
               <li>Gasoline</li>
               <li>Toll</li>
               <li>Parking</li>
               <li>Entrance Ticket Bromo</li>
               <li>4x4 Bromo Jeep</li>
               <li>Entrance Ticket Ijen Crater</li>
               <li>Entrance Ticket Malang / Batu city</li>
               <li>1 Night Hotel in Bromo & Breakfast</li>
               <li>1 Night Hotel in Ijen & Breakfast</li>
               <li>1 Night Hotel in Malang & Breakfast</li>
               <li>Mineral Water</li>
               <li>Wifi portable</li>
              </ul>
             </CardText> */}
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PIDE")}</h3>
                          </CardTitle>
                          {/* <CardText>
              <ul>
               <li>Lunch</li>
               <li>Dinner</li>
               <li>Horse in Bromo</li>
               <li>Madakaripura Cost</li>
               <li>Paragliding</li>
              </ul>
             </CardText> */}
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
          <div className="swiper">
            <Swiper
              modules={[
                Navigation,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay,
                Virtual,
              ]}
              allowSlideNext
              allowSlidePrev
              autoplay={{ delay: 5000 }}
              speed={1200}
              loop
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              spaceBetween={20}
            >
              <SwiperSlide className="item">
                <img src={images.G1} alt="Gallery 1" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G2} alt="Gallery 2" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G3} alt="Gallery 3" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G4} alt="Gallery 4" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G5} alt="Gallery 5" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G6} alt="Gallery 6" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G7} alt="Gallery 7" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G8} alt="Gallery 8" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G9} alt="Gallery 9" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G10} alt="Gallery 10" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G11} alt="Gallery 11" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G12} alt="Gallery 12" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G13} alt="Gallery 13" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G14} alt="Gallery 14" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G15} alt="Gallery 15" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G16} alt="Gallery 16" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G17} alt="Gallery 17" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G18} alt="Gallery 18" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G19} alt="Gallery 19" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G20} alt="Gallery 20" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G21} alt="Gallery 21" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G22} alt="Gallery 22" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G23} alt="Gallery 23" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G24} alt="Gallery 24" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G25} alt="Gallery 25" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G26} alt="Gallery 26" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G27} alt="Gallery 27" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G28} alt="Gallery 28" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G29} alt="Gallery 29" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G30} alt="Gallery 30" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G31} alt="Gallery 31" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G32} alt="Gallery 32" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G33} alt="Gallery 33" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G34} alt="Gallery 34" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G35} alt="Gallery 35" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G36} alt="Gallery 36" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G37} alt="Gallery 37" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G38} alt="Gallery 38" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G39} alt="Gallery 39" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G40} alt="Gallery 40" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G41} alt="Gallery 41" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G42} alt="Gallery 42" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G43} alt="Gallery 43" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G44} alt="Gallery 44" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G45} alt="Gallery 45" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G46} alt="Gallery 46" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
};

export default Destination18;
