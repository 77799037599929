import React from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCards,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
import "./index.css";

const Gallery = () => {
  const onInstagram = () => {
    window.open("https://www.instagram.com/indojavatrip/", "_blank");
  };

  const { t } = useTranslation("translations");

  return (
    <div className="gallery">
      <div className="contain">
        <div className="major">
          <div className="major-1">
            <div className="title">{t("gallery.title")}</div>
            <div className="detail">{t("gallery.description")}</div>
            <div className="button" onClick={onInstagram}>
              {t("button.gallery")}
            </div>
          </div>
          <div className="major-2">
            <div className="swiper">
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Autoplay,
                  EffectCards,
                ]}
                slidesPerView={1}
                allowSlideNext
                allowSlidePrev
                autoplay={{ delay: 5000, reverseDirection: true }}
                speed={1200}
                loop
                effect="cards"
                cardsEffect={{
                  rotate: 30,
                }}
                centeredSlides={true}
                centeredSlidesBounds={true}
                centerInsufficientSlides={true}
                grabCursor
              >
                <SwiperSlide className="item">
                  <img src={images.G1} alt="Gallery 1" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G2} alt="Gallery 2" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G3} alt="Gallery 3" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G4} alt="Gallery 4" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G5} alt="Gallery 5" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G6} alt="Gallery 6" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G7} alt="Gallery 7" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G8} alt="Gallery 8" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G9} alt="Gallery 9" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G10} alt="Gallery 10" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G11} alt="Gallery 11" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G12} alt="Gallery 12" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G13} alt="Gallery 13" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G14} alt="Gallery 14" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G15} alt="Gallery 15" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G16} alt="Gallery 16" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G17} alt="Gallery 17" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G18} alt="Gallery 18" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G19} alt="Gallery 19" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G20} alt="Gallery 20" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G21} alt="Gallery 21" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G22} alt="Gallery 22" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G23} alt="Gallery 23" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G24} alt="Gallery 24" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G25} alt="Gallery 25" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G26} alt="Gallery 26" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G27} alt="Gallery 27" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G28} alt="Gallery 28" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G29} alt="Gallery 29" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G30} alt="Gallery 30" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G31} alt="Gallery 31" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G32} alt="Gallery 32" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G33} alt="Gallery 33" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G34} alt="Gallery 34" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G35} alt="Gallery 35" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G36} alt="Gallery 36" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G37} alt="Gallery 37" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G38} alt="Gallery 38" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G39} alt="Gallery 39" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G40} alt="Gallery 40" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G41} alt="Gallery 41" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G42} alt="Gallery 42" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G43} alt="Gallery 43" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G44} alt="Gallery 44" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G45} alt="Gallery 45" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G46} alt="Gallery 46" />
                </SwiperSlide>
                <SwiperSlide className="item">
                  <img src={images.G47} alt="Gallery 47" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
