import React, { useEffect } from "react";
import images from "../../../assets/images";
import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useTranslation } from "react-i18next";

const Destination = () => {
  const { t } = useTranslation("translations");
  const history = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(
        ".destination .contain .header .title"
      );
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1.2) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(
        ".destination .contain .header .detail"
      );
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1.2) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(".destination .contain .body");
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1.2) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onDest3 = () => {
    history("/destination-3");
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const onDest4 = () => {
    history("/destination-4");
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const onAllPackage = () => {
    history("/all-package");
    window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <div className="destination">
      <div className="contain">
        <div className="header">
          <div className="title">{t("package.hp-title")}</div>
          <div className="detail">{t("package.hp-detail")}</div>
        </div>
        <div className="body">
          <div className="menu">
            <Card className="item">
              <img className="img" src={images.P3D2NM2} alt="Destination 1" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 1"
                onClick={onDest4}
              />
              <p className="head">{t("package.ej-3D2N")}</p>
              <a
                href="/east-java-3D2N-menu-2"
                rel="noopener noreferrer"
                className="title"
              >
                {t("3D2NM2.T")}
              </a>
              <p className="detail">{t("package-2.P2MD")}</p>
            </Card>
            <Card className="item">
              <img
                className="img"
                src={images.P3D2NM4}
                alt="Destination 2"
                onClick={onDest3}
              />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 2"
              />
              <p className="head">{t("package.ej-3D2N")}</p>
              <a
                href="/east-java-3D2N-menu-4"
                rel="noopener noreferrer"
                className="title"
              >
                {t("3D2NM4.T")}
              </a>
              <p className="detail">{t("package-2.P2MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P4D3NM1} alt="Destination 3" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 3"
              />
              <p className="head">{t("package.ej-4D3N")}</p>
              <a
                href="/east-java-4D3N-menu-1"
                rel="noopener noreferrer"
                className="title"
              >
                {t("4D3NM1.T")}
              </a>
              <p className="detail">{t("package-3.P3MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P4D3NM2} alt="Destination 4" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 4"
              />
              <p className="head">{t("package.ej-4D3N")}</p>
              <a
                href="/east-java-4D3N-menu-2"
                rel="noopener noreferrer"
                className="title"
              >
                {t("4D3NM2.T")}
              </a>
              <p className="detail">{t("package-3.P3MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P4D3NM3} alt="Destination 5" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 5"
              />
              <p className="head">{t("package.ej-4D3N")}</p>
              <a
                href="/east-java-4D3N-menu-3"
                rel="noopener noreferrer"
                className="title"
              >
                {t("4D3NM3.T")}
              </a>
              <p className="detail">{t("package-3.P3MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P4D3NM5} alt="Destination 6" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 6"
              />
              <p className="head">{t("package.ej-4D3N")}</p>
              <a
                href="/east-java-4D3N-menu-5"
                rel="noopener noreferrer"
                className="title"
              >
                {t("4D3NM5.T")}
              </a>
              <p className="detail">{t("package-3.P3MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P5D4NM1} alt="Destination 7" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 7"
              />
              <p className="head">{t("package.ej-5D4N")}</p>
              <a
                href="/east-java-5D4N-menu-1"
                rel="noopener noreferrer"
                className="title"
              >
                {t("5D4NM1.T")}
              </a>
              <p className="detail">{t("package-4.P4MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P5D4NM4} alt="Destination 8" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 8"
              />
              <p className="head">{t("package.ej-5D4N")}</p>
              <a
                href="/east-java-5D4N-menu-4"
                rel="noopener noreferrer"
                className="title"
              >
                {t("5D4NM4.T")}
              </a>
              <p className="detail">{t("package-4.P4MD")}</p>
            </Card>
            <Card className="item">
              <img className="img" src={images.P6D5NM1} alt="Destination 9" />
              <div className="star">
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="full">&#9733;</span>
                <span className="half">&#9733;</span>
              </div>
              <img
                className="label"
                src={images.hotSale2}
                alt="Label Destination 9"
              />
              <p className="head">{t("package.ej-6D5N")}</p>
              <a
                href="/east-java-6D5N-menu-1"
                rel="noopener noreferrer"
                className="title"
              >
                {t("6D5NM1.T")}
              </a>
              <p className="detail">{t("package-5.P5MD")}</p>
            </Card>
          </div>
          <div className="button" type="button" onClick={onAllPackage}>
            {t("button.all-package")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Destination;
