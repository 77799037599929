import React, { useState, useEffect } from "react";
import "./index.css";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import images from "../../../assets/images";
import { BsFillCalendar2EventFill } from "react-icons/bs";
import Contact from "../../../components/main/contact";
import Testimonial from "../../../components/main/testimonial";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/virtual";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const Destination12 = () => {
  const { t } = useTranslation("translations");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onWhatsApp = () => {
    const phoneNumber = "6281390070766";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <div className="dest12">
      <Navbar />
      <div className="cont">
        <div className="bod">
          <div className="cov">
            <img src={images.L6D4N} alt="Dest 1" />
          </div>
          <div className="hea">
            <h1>{t("4D3NM6.T")}</h1>
            {/* <p>{t("package-menu-description")}</p> */}
          </div>
          <div className="mai">
            <div className="left">
              <Card className="card">
                <div className="single-price">
                  <h5>{t("destination.PPPT")}</h5>
                  {/* <h4>{t("package-private-price-description")}</h4> */}
                  <h4>Rp. 2.750.000/PAX</h4>
                </div>
                <div className="line"></div>
                <div className="custom-price">
                  <h5>{t("destination.PCPT")}</h5>
                  <h4>{t("destination.PCPD")}</h4>
                </div>
                <div
                  className="button-order"
                  type="button"
                  onClick={onWhatsApp}
                >
                  {t("button.order")}
                </div>
              </Card>
            </div>
            <div className="right">
              <div className="heade">
                <BsFillCalendar2EventFill className="ico" />
                <h2>{t("destination.PIT")}</h2>
              </div>
              <div className="bodi">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggle("1");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID1")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggle("2");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID2")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggle("3");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID3")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={() => {
                        toggle("4");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PID4")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "5" ? "active" : ""}
                      onClick={() => {
                        toggle("5");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PIDI")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "6" ? "active" : ""}
                      onClick={() => {
                        toggle("6");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("destination.PIDE")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <h6 style={{ color: "red" }}>
                            {t("destination.PN")}
                          </h6>
                          <h6 style={{ color: "red" }}>
                            - {t("destination.PNM3")}
                          </h6>
                          <h6 style={{ color: "red" }}>
                            - {t("destination.PNM1")}
                          </h6>
                          <CardTitle>
                            <h3 style={{ marginTop: "20px" }}>
                              {t("destination.PID1")}
                            </h3>
                          </CardTitle>
                          <CardText>
                            <ul>
                              <li>{t("4D3NM6.D1L1")}</li>
                              <li>{t("4D3NM6.D1L2")}</li>
                              <li>{t("4D3NM6.D1L3")}</li>
                            </ul>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PID2")}</h3>
                          </CardTitle>
                          <CardText>
                            <ul>
                              <li>{t("4D3NM6.D2L1")}</li>
                              <li>{t("4D3NM6.D2L2")}</li>
                              <li>{t("4D3NM6.D2L3")}</li>
                              <li>{t("4D3NM6.D2L4")}</li>
                              <li>{t("4D3NM6.D2L5")}</li>
                              <li>{t("4D3NM6.D2L6")}</li>
                            </ul>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PID3")}</h3>
                          </CardTitle>
                          <CardText>
                            <ul>
                              <li>{t("4D3NM6.D3L1")}</li>
                              <li>{t("4D3NM6.D3L2")}</li>
                              <li>{t("4D3NM6.D3L3")}</li>
                              <li>{t("4D3NM6.D3L4")}</li>
                              <li>{t("4D3NM6.D3L5")}</li>
                              <li>{t("4D3NM6.D3L6")}</li>
                              <li>{t("4D3NM6.D3L7")}</li>
                              <li>{t("4D3NM6.D3L8")}</li>
                              <li>{t("4D3NM6.D3L9")}</li>
                              <li>{t("4D3NM6.D3L10")}</li>
                            </ul>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PID4")}</h3>
                          </CardTitle>
                          <CardText>
                            <ul>
                              <li>{t("4D3NM6.D4L1")}</li>
                              <li>{t("4D3NM6.D4L2")}</li>
                              <li>{t("4D3NM6.D4L3")}</li>
                              <li>{t("4D3NM6.D4L4")}</li>
                              <li>{t("4D3NM6.D4L5")}</li>
                              <li>{t("4D3NM6.D4L6")}</li>
                              <li>{t("4D3NM6.D4L7")}</li>
                              <li>{t("4D3NM6.D4L8")}</li>
                            </ul>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PIDI")}</h3>
                          </CardTitle>
                          <CardText>
                            <ul>
                              <li>{t("4D3NM6.IL1")}</li>
                              <li>{t("4D3NM6.IL2")}</li>
                              <li>{t("4D3NM6.IL3")}</li>
                              <li>{t("4D3NM6.IL4")}</li>
                              <li>{t("4D3NM6.IL5")}</li>
                              <li>{t("4D3NM6.IL6")}</li>
                              <li>{t("4D3NM6.IL7")}</li>
                              <li>{t("4D3NM6.IL8")}</li>
                              <li>{t("4D3NM6.IL9")}</li>
                            </ul>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row>
                      <Col sm="12">
                        <Card body>
                          <CardTitle>
                            <h3>{t("destination.PIDE")}</h3>
                          </CardTitle>
                          <CardText>
                            <ul>
                              <li>{t("4D3NM6.EL1")}</li>
                              <li>{t("4D3NM6.EL2")}</li>
                              <li>{t("4D3NM6.EL3")}</li>
                              <li>{t("4D3NM6.EL4")}</li>
                            </ul>
                          </CardText>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
          <div className="swiper">
            <Swiper
              modules={[
                Navigation,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay,
                Virtual,
              ]}
              allowSlideNext
              allowSlidePrev
              autoplay={{ delay: 5000 }}
              speed={1200}
              loop
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              spaceBetween={20}
            >
              <SwiperSlide className="item">
                <img src={images.G1} alt="Gallery 1" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G2} alt="Gallery 2" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G3} alt="Gallery 3" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G4} alt="Gallery 4" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G5} alt="Gallery 5" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G6} alt="Gallery 6" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G7} alt="Gallery 7" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G8} alt="Gallery 8" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G9} alt="Gallery 9" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G10} alt="Gallery 10" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G11} alt="Gallery 11" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G12} alt="Gallery 12" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G13} alt="Gallery 13" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G14} alt="Gallery 14" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G15} alt="Gallery 15" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G16} alt="Gallery 16" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G17} alt="Gallery 17" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G18} alt="Gallery 18" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G19} alt="Gallery 19" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G20} alt="Gallery 20" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G21} alt="Gallery 21" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G22} alt="Gallery 22" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G23} alt="Gallery 23" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G24} alt="Gallery 24" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G25} alt="Gallery 25" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G26} alt="Gallery 26" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G27} alt="Gallery 27" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G28} alt="Gallery 28" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G29} alt="Gallery 29" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G30} alt="Gallery 30" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G31} alt="Gallery 31" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G32} alt="Gallery 32" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G33} alt="Gallery 33" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G34} alt="Gallery 34" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G35} alt="Gallery 35" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G36} alt="Gallery 36" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G37} alt="Gallery 37" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G38} alt="Gallery 38" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G39} alt="Gallery 39" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G40} alt="Gallery 40" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G41} alt="Gallery 41" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G42} alt="Gallery 42" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G43} alt="Gallery 43" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G44} alt="Gallery 44" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G45} alt="Gallery 45" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G46} alt="Gallery 46" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
};

export default Destination12;
