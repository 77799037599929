import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './components/languange/index'
import 'bootstrap/dist/css/bootstrap.min.css'

const language = localStorage.getItem('lang') || 'en'
localStorage.setItem('lang', language)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
 <React.StrictMode>
  <App />
 </React.StrictMode>
)

reportWebVitals()
