import React, { useState, useEffect } from 'react'
import images from '../../assets/images'
import Select from 'react-select'
import { Input, Button } from 'reactstrap'
import Swal from 'sweetalert2'
import './index.css'

const Review = () => {
 const [review, setReview] = useState({
  nama: '',
  bintang: '',
  ulasan: '',
 })

 const starOption = [
  { value: '1', label: '1 Star', name: 'bintang', key: '0' },
  { value: '2', label: '2 Star', name: 'bintang', key: '1' },
  { value: '3', label: '3 Star', name: 'bintang', key: '2' },
  { value: '4', label: '4 Star', name: 'bintang', key: '3' },
  { value: '5', label: '5 Star', name: 'bintang', key: '4' },
 ]

 const onChange = (e) => {
  let name, value

  if (e.name) {
   name = e.name
   value = e.value
  } else {
   name = e.target.name
   value = e.target.value
  }

  if (name === 'bintang') {
   setReview((prevState) => ({
    ...prevState,
    [name]: parseInt(value),
   }))
  } else {
   setReview((prevState) => ({
    ...prevState,
    [name]: value,
   }))
  }
 }

 const onReset = () => {
  setReview({
   nama: '',
   bintang: '',
   ulasan: '',
  })
 }

 const onSubmit = async () => {
  try {
   const response = await fetch('https://app-be.indojavatrip.com/rv', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify(review),
   })

   if (response.ok) {
    console.log('Data berhasil dikirim')
    await Swal.fire(
     'Data Berhasil Dikirim',
     'Terima kasih telah berbagi pengalamanmu!',
     'success'
    )
    onReset()
   } else {
    console.error('Gagal mengirim data')
    await Swal.fire(
     'Gagal Mengirim Data',
     'Terdapat kesalahan saat mengirim data. Mohon coba lagi nanti.',
     'error'
    )
   }
  } catch (error) {
   console.error('Terjadi kesalahan:', error)
   await Swal.fire(
    'Terjadi Kesalahan',
    'Terdapat kesalahan saat memproses permintaan. Mohon coba lagi nanti.',
    'error'
   )
  }
 }

 console.log(review)

 return (
  <div className="review">
   <div className="cont-review">
    <div className="cont-review-left">
     <img src={images.bgReview} alt="BG Review" />
    </div>
    <div className="cont-review-right">
     <div className="cont-form">
      <h2>Review</h2>
      <div className="form">
       <Input
        type="text"
        id="nama"
        name="nama"
        placeholder="Fullname"
        onChange={onChange}
       />
       <Select
        className=""
        options={starOption}
        id="bintang"
        name="bintang"
        defaultValue={{ label: 'Choose Star', value: '', name: 'bintang' }}
        onChange={onChange}
       />
       <Input
        type="textarea"
        id="ulasan"
        name="ulasan"
        placeholder="Review..."
        rows="6"
        cols="50"
        onChange={onChange}
       />
       <Button color="primary" onClick={onSubmit}>
        Submit
       </Button>
      </div>
     </div>
    </div>
   </div>
  </div>
 )
}

export default Review
