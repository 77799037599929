import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Main from "../pages/main";
import Package1 from "../pages/package/1";
import Package2 from "../pages/package/2";
import Package3 from "../pages/package/3";
import Package4 from "../pages/package/4";
import Package5 from "../pages/package/5";
import Package6 from "../pages/package/6";
import Package7 from "../pages/package/7";
import Package8 from "../pages/package/8";
import Package9 from "../pages/package/9";
import PackageAll from "../pages/package/all";
import Destination1 from "../pages/destination/1";
import Destination2 from "../pages/destination/2";
import Destination3 from "../pages/destination/3";
import Destination4 from "../pages/destination/4";
import Destination5 from "../pages/destination/5";
import Destination6 from "../pages/destination/6";
import Destination7 from "../pages/destination/7";
import Destination8 from "../pages/destination/8";
import Destination9 from "../pages/destination/9";
import Destination10 from "../pages/destination/10";
import Destination11 from "../pages/destination/11";
import Destination12 from "../pages/destination/12";
import Destination13 from "../pages/destination/13";
import Destination14 from "../pages/destination/14";
import Destination15 from "../pages/destination/15";
import Destination16 from "../pages/destination/16";
import Destination17 from "../pages/destination/17";
import Destination18 from "../pages/destination/18";
import Destination19 from "../pages/destination/19";
import Destination21 from "../pages/destination/21";
import Destination22 from "../pages/destination/22";
import Review from "../pages/review";
import Destination20 from "../pages/destination/20";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Main />} />
        <Route path="/east-java-2D1N" index element={<Package1 />} />
        <Route path="/east-java-3D2N" index element={<Package2 />} />
        <Route path="/east-java-4D3N" index element={<Package3 />} />
        <Route path="/east-java-5D4N" index element={<Package4 />} />
        <Route path="/east-java-6D5N" index element={<Package5 />} />
        <Route path="/central-java" index element={<Package6 />} />
        <Route path="/lombok" index element={<Package7 />} />
        <Route path="/semeru" index element={<Package8 />} />
        <Route path="/east-center-java" index element={<Package9 />} />
        <Route path="/all-package" index element={<PackageAll />} />
        <Route path="/east-java-2D1N-menu-1" index element={<Destination1 />} />
        <Route path="/east-java-2D1N-menu-2" index element={<Destination2 />} />
        <Route path="/east-java-3D2N-menu-1" index element={<Destination3 />} />
        <Route path="/east-java-3D2N-menu-2" index element={<Destination4 />} />
        <Route path="/east-java-3D2N-menu-3" index element={<Destination5 />} />
        <Route path="/east-java-3D2N-menu-4" index element={<Destination6 />} />
        <Route path="/east-java-4D3N-menu-1" index element={<Destination7 />} />
        <Route path="/east-java-4D3N-menu-2" index element={<Destination8 />} />
        <Route path="/east-java-4D3N-menu-3" index element={<Destination9 />} />
        <Route
          path="/east-java-4D3N-menu-4"
          index
          element={<Destination10 />}
        />
        <Route
          path="/east-java-4D3N-menu-5"
          index
          element={<Destination11 />}
        />
        <Route
          path="/east-java-4D3N-menu-6"
          index
          element={<Destination12 />}
        />
        <Route
          path="/east-java-5D4N-menu-1"
          index
          element={<Destination13 />}
        />
        <Route
          path="/east-java-5D4N-menu-2"
          index
          element={<Destination14 />}
        />
        <Route
          path="/east-java-5D4N-menu-3"
          index
          element={<Destination15 />}
        />
        <Route
          path="/east-java-5D4N-menu-4"
          index
          element={<Destination16 />}
        />
        <Route
          path="/east-java-6D5N-menu-1"
          index
          element={<Destination17 />}
        />
        <Route path="/semeru-menu-1" index element={<Destination18 />} />
        <Route path="/semeru-menu-2" index element={<Destination19 />} />
        <Route path="/semeru-menu-3" index element={<Destination20 />} />
        <Route path="/lombok-menu-1" index element={<Destination21 />} />
        <Route path="/lombok-menu-2" index element={<Destination22 />} />
        <Route path="/review" index element={<Review />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
