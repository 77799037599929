import React from "react";
import images from "../../../assets/images";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { Card } from "reactstrap";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/virtual";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import "./index.css";
import Contact from "../../../components/main/contact";
import Testimonial from "../../../components/main/testimonial";
import { useTranslation } from "react-i18next";

const Package3 = () => {
  const { t } = useTranslation("translations");

  return (
    <div className="package3">
      <Navbar />
      <div className="contain">
        <div className="body">
          <div className="cover">
            <img src={images.L4D3N} alt="Package 1" />
          </div>
          <div className="header-p3">
            <h1>{t("package-3.P3T")}</h1>
            <p>{t("package-3.P3D")}</p>
          </div>
          <div className="main">
            <h1>{t("button.choose-package")}</h1>
            <div className="menu">
              <Card className="item">
                <img className="img" src={images.P4D3NM1} alt="Menu 1" />
                <div className="star">
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                </div>
                <img
                  className="label"
                  src={images.hotSale2}
                  alt="Label Destination 2"
                />
                <h6>{t("package.ej-4D3N")}</h6>
                <a href="/east-java-4D3N-menu-1" rel="noopener noreferrer">
                  {t("package-3.P3M1")}
                </a>
                <p>{t("package-3.P3MD")}</p>
              </Card>
              <Card className="item">
                <img className="img" src={images.P4D3NM2} alt="Menu 2" />
                <div className="star">
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                </div>
                <img
                  className="label"
                  src={images.hotSale2}
                  alt="Label Destination 2"
                />
                <h6>{t("package.ej-4D3N")}</h6>
                <a href="/east-java-4D3N-menu-2" rel="noopener noreferrer">
                  {t("package-3.P3M2")}
                </a>
                <p>{t("package-3.P3MD")}</p>
              </Card>
              <Card className="item">
                <img className="img" src={images.P4D3NM3} alt="Menu 3" />
                <div className="star">
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                </div>
                <img
                  className="label"
                  src={images.hotSale2}
                  alt="Label Destination 3"
                />
                <h6>{t("package.ej-4D3N")}</h6>
                <a href="/east-java-4D3N-menu-3" rel="noopener noreferrer">
                  {t("package-3.P3M3")}
                </a>
                <p>{t("package-3.P3MD")}</p>
              </Card>
              <Card className="item">
                <img className="img" src={images.P4D3NM4} alt="Menu 4" />
                <div className="star">
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="half">&#9733;</span>
                </div>
                <h6>{t("package.ej-4D3N")}</h6>
                <a href="/east-java-4D3N-menu-4" rel="noopener noreferrer">
                  {t("package-3.P3M4")}
                </a>
                <p>{t("package-3.P3MD")}</p>
              </Card>
              <Card className="item">
                <img className="img" src={images.P4D3NM5} alt="Menu 5" />
                <div className="star">
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                </div>
                <img
                  className="label"
                  src={images.hotSale2}
                  alt="Label Destination 5"
                />
                <h6>{t("package.ej-4D3N")}</h6>
                <a href="/east-java-4D3N-menu-5" rel="noopener noreferrer">
                  {t("package-3.P3M5")}
                </a>
                <p>{t("package-3.P3MD")}</p>
              </Card>
              <Card className="item">
                <img className="img" src={images.P4D3NM6} alt="Menu 6" />
                <div className="star">
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                  <span className="full">&#9733;</span>
                </div>
                <img
                  className="label"
                  src={images.hotSale2}
                  alt="Label Destination 6"
                />
                <h6>{t("package.ej-4D3N")}</h6>
                <a href="/east-java-4D3N-menu-6" rel="noopener noreferrer">
                  {t("package-3.P3M6")}
                </a>
                <p>{t("package-3.P3MD")}</p>
              </Card>
            </div>
          </div>
          <div className="swiper">
            <Swiper
              modules={[
                Navigation,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay,
                Virtual,
              ]}
              allowSlideNext
              allowSlidePrev
              autoplay={{ delay: 5000 }}
              speed={1200}
              loop
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              spaceBetween={20}
            >
              <SwiperSlide className="item">
                <img src={images.G1} alt="Gallery 1" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G2} alt="Gallery 2" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G3} alt="Gallery 3" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G4} alt="Gallery 4" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G5} alt="Gallery 5" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G6} alt="Gallery 6" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G7} alt="Gallery 7" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G8} alt="Gallery 8" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G9} alt="Gallery 9" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G10} alt="Gallery 10" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G11} alt="Gallery 11" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G12} alt="Gallery 12" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G13} alt="Gallery 13" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G14} alt="Gallery 14" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G15} alt="Gallery 15" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G16} alt="Gallery 16" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G17} alt="Gallery 17" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G18} alt="Gallery 18" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G19} alt="Gallery 19" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G20} alt="Gallery 20" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G21} alt="Gallery 21" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G22} alt="Gallery 22" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G23} alt="Gallery 23" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G24} alt="Gallery 24" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G25} alt="Gallery 25" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G26} alt="Gallery 26" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G27} alt="Gallery 27" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G28} alt="Gallery 28" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G29} alt="Gallery 29" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G30} alt="Gallery 30" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G31} alt="Gallery 31" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G32} alt="Gallery 32" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G33} alt="Gallery 33" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G34} alt="Gallery 34" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G35} alt="Gallery 35" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G36} alt="Gallery 36" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G37} alt="Gallery 37" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G38} alt="Gallery 38" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G39} alt="Gallery 39" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G40} alt="Gallery 40" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G41} alt="Gallery 41" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G42} alt="Gallery 42" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G43} alt="Gallery 43" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G44} alt="Gallery 44" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G45} alt="Gallery 45" />
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G46} alt="Gallery 46" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
};

export default Package3;
