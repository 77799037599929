import React, { useEffect } from "react";
import images from "../../../assets/images";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCoverflow,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import "./index.css";

const About = () => {
  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(".about .contain .major .title");
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { t } = useTranslation("translations");

  return (
    <div className="about">
      <div className="contain">
        <div className="major">
          <div className="title">{t("about.title")}</div>
          <div className="menu">
            <div className="description">{t("about.description")}</div>
            <div className="swiper">
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Autoplay,
                  EffectCoverflow,
                ]}
                breakpoints={{
                  1024: {
                    slidesPerView: 3,
                  },
                  983: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  356: {
                    slidesPerView: 1,
                  },
                }}
                allowSlideNext
                allowSlidePrev
                autoplay={{ delay: 5000 }}
                speed={1200}
                loop
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 30,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
              >
                <SwiperSlide className="item">
                  <div className="background">
                    <div className="description">
                      <div className="header" style={{ color: "white" }}>
                        {t("about.IT1")}
                      </div>
                      <div className="detail">{t("about.ID1")}</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="item">
                  <div className="background">
                    <div className="description">
                      <div className="header" style={{ color: "white" }}>
                        {t("about.IT2")}
                      </div>
                      <div className="detail">{t("about.ID2")}</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="item">
                  <div className="background">
                    <div className="description">
                      <div className="header" style={{ color: "white" }}>
                        {t("about.IT3")}
                      </div>
                      <div className="detail">{t("about.ID3")}</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="item">
                  <div className="background">
                    <div className="description">
                      <div className="header" style={{ color: "white" }}>
                        {t("about.IT1")}
                      </div>
                      <div className="detail">{t("about.ID1")}</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="item">
                  <div className="background">
                    <div className="description">
                      <div className="header" style={{ color: "white" }}>
                        {t("about.IT2")}
                      </div>
                      <div className="detail">{t("about.ID2")}</div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="item">
                  <div className="background">
                    <div className="description">
                      <div className="header" style={{ color: "white" }}>
                        {t("about.IT3")}
                      </div>
                      <div className="detail">{t("about.ID3")}</div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="brand">
            <img className="brand" src={images.maps2} alt="Maps" />
            <div className="circle">
              <div className="circular"></div>
              <div className="plane">
                <img src={images.plane3} alt="Plane" />
              </div>
            </div>
          </div>
        </div>
        <div className="maps-legend">
          <h3>{t("title.east-java")}</h3>
          <div className="list">
            <div className="one">
              <h4>{t("maps.BT")}</h4>
              <h5>{t("maps.BD")}</h5>
            </div>
            <div className="two">
              <h4>{t("maps.IT")}</h4>
              <h5>{t("maps.ID")}</h5>
            </div>
            <div className="three">
              <h4>{t("maps.TT")}</h4>
              <h5>{t("maps.TD")}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
