import './App.css'
import Router from './routes'

const App = () => {
 return (
  <>
   <Router />
  </>
 )
}

export default App
