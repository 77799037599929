import React from "react";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images";
import {
  FaInstagram,
  FaTiktok,
  FaFacebookSquare,
  FaWhatsapp,
} from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import "./index.css";

const Footer = () => {
  const history = useNavigate();

  const onHome = () => {
    history("/");
    window.location.reload();
    window.scrollTo(0, 0);
  };

  const onInstagram = () => {
    window.open("https://www.instagram.com/indojavatrip/", "_blank");
  };

  const onTiktok = () => {
    window.open("https://www.tiktok.com/@indojavatriporganizer", "_blank");
  };

  const onFacebook = () => {
    window.open("https://www.facebook.com/indojavatrip/", "_blank");
  };

  const { t } = useTranslation("translations");

  return (
    <div className="footer">
      <div className="contain">
        <div className="body">
          <div className="brand">
            <img src={images.logo} alt="Brand" />
            <div className="detail">{t("footer.footer-1D")}</div>
          </div>
          <div className="links">
            <div className="header">{t("footer.footer-2T")}</div>
            <div className="item" onClick={onHome}>
              {t("nav.home")}
            </div>
          </div>
          <div className="category">
            <div className="header">{t("footer.footer-3T")}</div>
            <a href="/package-1" className="item">
              {t("nav.east-java.2D1N")}
            </a>
            <a href="/package-2" className="item">
              {t("nav.east-java.3D2N")}
            </a>
            <a href="/package-3" className="item">
              {t("nav.east-java.4D3N")}
            </a>
            <a href="/package-4" className="item">
              {t("nav.east-java.5D4N")}
            </a>
            <a href="/package-5" className="item">
              {t("nav.east-java.6D5N")}
            </a>
            <a href="/package-6" className="item">
              {t("nav.central-java")}
            </a>
            <a href="/package-7" className="item">
              {t("nav.lombok")}
            </a>
            <a href="/package-8" className="item">
              {t("nav.semeru")}
            </a>
            <a href="/package-9" className="item">
              {t("nav.ec-java")}
            </a>
          </div>
          <div className="connection">
            <div className="header">{t("footer.footer-4T")}</div>
            <div className="item">
              <FaWhatsapp className="logo" />
              <p>+62 813-9007-0766</p>
            </div>
            <div className="item">
              <MdOutlineMail className="logo" />
              <p>indojavatrip@gmail.com</p>
            </div>
          </div>
          <div className="social">
            <div className="header">{t("footer.footer-5T")}</div>
            <div className="item">
              <FaInstagram className="logo" onClick={onInstagram} />
              <p onClick={onInstagram}>{t("media.social-instagram")}</p>
            </div>
            <div className="item">
              <FaTiktok className="logo" onClick={onTiktok} />
              <p onClick={onTiktok}>{t("media.social-tiktok")}</p>
            </div>
            <div className="item">
              <FaFacebookSquare className="logo" onClick={onFacebook} />
              <p onClick={onFacebook}>{t("media.social-facebook")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
