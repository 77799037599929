/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
import "./index.css";

const Package = () => {
  const history = useNavigate();

  const { t } = useTranslation("translations");

  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(".package .contain .header .title");
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1.2) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(".package .contain .header .detail");
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1.2) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelector(".package .contain .body");
      const titlePosition = title.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (titlePosition < windowHeight / 1.2) {
        title.classList.add("slide-in");
      } else {
        title.classList.remove("slide-in");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="package">
      <div className="contain">
        <div className="header">
          <div className="title">{t("title.package")}</div>
          <div className="detail">{t("title.package-description")}</div>
        </div>
        <div className="body">
          <div className="swiper">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                983: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                360: {
                  slidesPerView: 1,
                },
              }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              allowSlideNext
              allowSlidePrev
              autoplay={{ delay: 10000 }}
              speed={1200}
              loop
            >
              <SwiperSlide className="item">
                <img src={images.cov2D1N} alt="Swiper 1" />
                <div className="pakej">
                  <h3>{t("title.east-java")}</h3>
                  <h5>{t("package.ej-2D1N")}</h5>
                </div>
                <a
                  href="/east-java-2D1N"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.cov3D2N} alt="Swiper 2" />
                <div className="pakej">
                  <h3>{t("title.east-java")}</h3>
                  <h5>{t("package.ej-3D2N")}</h5>
                </div>
                <a
                  href="/east-java-3D2N"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.cov4D3N} alt="Swiper 3" />
                <div className="pakej">
                  <h3>{t("title.east-java")}</h3>
                  <h5>{t("package.ej-4D3N")}</h5>
                </div>
                <a
                  href="/east-java-4D3N"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.cov5D4N} alt="Swiper 4" />
                <div className="pakej">
                  <h3>{t("title.east-java")}</h3>
                  <h5>{t("package.ej-5D4N")}</h5>
                </div>
                <a
                  href="/east-java-5D4N"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.cov6D5N} alt="Swiper 5" />
                <div className="pakej">
                  <h3>{t("title.east-java")}</h3>
                  <h5>{t("package.ej-6D5N")}</h5>
                </div>
                <a
                  href="/east-java-6D5N"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G1} alt="Swiper 6" />
                <div className="pakej">
                  <h3>{t("title.semeru")}</h3>
                  <h5>{t("package.semeru")}</h5>
                </div>
                <a href="/semeru" rel="noopener noreferrer" className="label">
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G1} alt="Swiper 7" />
                <div className="pakej central">
                  <h3>{t("title.central-java")}</h3>
                  <h5>{t("package.center-java")}</h5>
                </div>
                <a
                  href="/central-java"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G1} alt="Swiper 8" />
                <div className="pakej">
                  <h3>{t("title.lombok")}</h3>
                  <h5>{t("package.lombok")}</h5>
                </div>
                <a href="/lombok" rel="noopener noreferrer" className="label">
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
              <SwiperSlide className="item">
                <img src={images.G1} alt="Swiper 9" />
                <div className="pakej">
                  <h3>{t("title.ec-java")}</h3>
                  <h5>{t("package.ec-java")}</h5>
                </div>
                <a
                  href="/east-central-java"
                  rel="noopener noreferrer"
                  className="label"
                >
                  {t("button.choose-package")}
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
