import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../languange/json/en.json'
import id from '../languange/json/id.json'
import ms from '../languange/json/ms.json'
import ch from '../languange/json/ch.json'

i18n.use(initReactI18next).init({
 fallbackLng: 'en',
 lng: localStorage.getItem('lang') || 'en',
 resources: {
  en: {
   translations: en,
  },
  id: {
   translations: id,
  },
  ms: {
   translations: ms,
  },
  ch: {
   translations: ch,
  },
 },
 ns: ['translations'],
 defaultNS: 'translations',
})

i18n.languages = ['en', 'id', 'ms', 'ch']

export default i18n
