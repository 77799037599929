import React, { useState, useEffect } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import "./index.css";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const { t } = useTranslation("translations");
  const [reviewData, setReviewData] = useState([]);

  const getReview = async () => {
    try {
      const response = await axios.get("https://app-be.indojavatrip.com/rv");
      console.log(response.data.data);
      if (response.data.data) {
        setReviewData(response.data.data);
      }
    } catch (error) {
      console.error("There was a problem with your axios operation:", error);
    }
  };

  useEffect(() => {
    getReview();
  }, []);

  const renderStars = (count) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(
        <span className="full" key={i}>
          &#9733;
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="testimonial">
      <div className="contain">
        <div className="header">
          <div className="title">{t("testimonial.title")}</div>
          <div className="detail">{t("testimonial.description")}</div>
        </div>
        <div className="body">
          <div className="swiper">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                983: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                356: {
                  slidesPerView: 1,
                },
              }}
              allowSlideNext
              allowSlidePrev
              autoplay={{ delay: 5000 }}
              speed={1200}
              loop
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 30,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
            >
              {reviewData.map((testimonial) => (
                <SwiperSlide key={testimonial.id} className="item">
                  <div className="background">
                    <div className="description">
                      <div className="star">
                        {renderStars(testimonial.bintang)}
                      </div>
                      <div className="header">{testimonial.nama}</div>
                      <div className="detail">{testimonial.ulasan}</div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
