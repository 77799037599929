import logo from "./logo/logo.png";
import hotSale2 from "./logo/hotsa.png";

import team1 from "./team/team1.png";
import team2 from "./team/team2.png";
import team3 from "./team/team3.png";
import team4 from "./team/team4.png";

import maps2 from "./maps/maps2.png";

import plane3 from "./plane/plane3.png";

import bgReview from "./review/bg-review.png";

import cov2D1N from "./package/2D1N/2D1NC.JPG";
import cov3D2N from "./package/3D2N/3D2NC.JPG";
import cov4D3N from "./package/4D3N/4D3NC.JPG";
import cov5D4N from "./package/5D4N/5D4NC.JPG";
import cov6D5N from "./package/6D5N/6D5NC.JPG";

import L2D1N from "./random/LP/2D1NL.jpg";
import P2D1NM1 from "./package/2D1N/2D1NM1P.jpg";
import P2D1NM2 from "./package/2D1N/2D1NM2P.jpg";

import L3D2N from "./random/LP/3D2NL.jpg";
import P3D2NM1 from "./package/3D2N/3D2NM1P.jpg";
import P3D2NM2 from "./package/3D2N/3D2NM2P.jpg";
import P3D2NM3 from "./package/3D2N/3D2NM3P.jpg";
import P3D2NM4 from "./package/3D2N/3D2NM4P.jpg";

import L4D3N from "./random/LP/4D3NL.jpg";
import P4D3NM1 from "./package/4D3N/4D3NM1P.jpg";
import P4D3NM2 from "./package/4D3N/4D3NM2P.jpg";
import P4D3NM3 from "./package/4D3N/4D3NM3P.jpg";
import P4D3NM4 from "./package/4D3N/4D3NM4P.jpg";
import P4D3NM5 from "./package/4D3N/4D3NM5P.jpg";
import P4D3NM6 from "./package/4D3N/4D3NM6P.jpg";

import L5D4N from "./random/LP/5D4NL.jpg";
import P5D4NM1 from "./package/5D4N/5D4NM1P.jpg";
import P5D4NM2 from "./package/5D4N/5D4NM2P.jpg";
import P5D4NM3 from "./package/5D4N/5D4NM3P.jpg";
import P5D4NM4 from "./package/5D4N/5D4NM4P.jpg";

import L6D4N from "./random/LP/6D5NL.jpg";
import P6D5NM1 from "./package/6D5N/6D5NM1P.jpg";

import LCJ from "./random/LP/CJL.jpg";

import LECJ from "./random/LP/ECJL.jpg";

import LL from "./random/LP/LL.jpg";

import LS from "./random/LP/SL.jpg";

import G1 from "./random/G/G1.JPG";
import G2 from "./random/G/G2.JPG";
import G3 from "./random/G/G3.JPG";
import G4 from "./random/G/G4.JPG";
import G5 from "./random/G/G5.JPG";
import G6 from "./random/G/G6.JPG";
import G7 from "./random/G/G7.JPG";
import G8 from "./random/G/G8.JPG";
import G9 from "./random/G/G9.JPG";
import G10 from "./random/G/G10.JPG";
import G11 from "./random/G/G11.JPG";
import G12 from "./random/G/G12.JPG";
import G13 from "./random/G/G13.JPG";
import G14 from "./random/G/G14.JPG";
import G15 from "./random/G/G15.JPG";
import G16 from "./random/G/G16.JPG";
import G17 from "./random/G/G17.JPG";
import G18 from "./random/G/G18.JPG";
import G19 from "./random/G/G19.JPG";
import G20 from "./random/G/G20.JPG";
import G21 from "./random/G/G21.JPG";
import G22 from "./random/G/G22.JPG";
import G23 from "./random/G/G23.JPG";
import G24 from "./random/G/G24.JPG";
import G25 from "./random/G/G25.JPG";
import G26 from "./random/G/G26.JPG";
import G27 from "./random/G/G27.JPG";
import G28 from "./random/G/G28.JPG";
import G29 from "./random/G/G29.JPG";
import G30 from "./random/G/G30.JPG";
import G31 from "./random/G/G31.JPG";
import G32 from "./random/G/G32.JPG";
import G33 from "./random/G/G33.JPG";
import G34 from "./random/G/G34.JPG";
import G35 from "./random/G/G35.JPG";
import G36 from "./random/G/G36.JPG";
import G37 from "./random/G/G37.JPG";
import G38 from "./random/G/G38.JPG";
import G39 from "./random/G/G39.JPG";
import G40 from "./random/G/G40.JPG";
import G41 from "./random/G/G41.JPG";
import G42 from "./random/G/G42.JPG";
import G43 from "./random/G/G43.JPG";
import G44 from "./random/G/G44.JPG";
import G45 from "./random/G/G45.JPG";
import G46 from "./random/G/G46.JPG";

const images = {
  logo,
  hotSale2,
  team1,
  team2,
  team3,
  team4,
  maps2,
  plane3,
  bgReview,
  cov2D1N,
  cov3D2N,
  cov4D3N,
  cov5D4N,
  cov6D5N,
  L2D1N,
  P2D1NM1,
  P2D1NM2,
  L3D2N,
  P3D2NM1,
  P3D2NM2,
  P3D2NM3,
  P3D2NM4,
  L4D3N,
  P4D3NM1,
  P4D3NM2,
  P4D3NM3,
  P4D3NM4,
  P4D3NM5,
  P4D3NM6,
  L5D4N,
  P5D4NM1,
  P5D4NM2,
  P5D4NM3,
  P5D4NM4,
  L6D4N,
  P6D5NM1,
  LCJ,
  LECJ,
  LL,
  LS,
  G1,
  G2,
  G3,
  G4,
  G5,
  G6,
  G7,
  G8,
  G9,
  G10,
  G11,
  G12,
  G13,
  G14,
  G15,
  G16,
  G17,
  G18,
  G19,
  G20,
  G21,
  G22,
  G23,
  G24,
  G25,
  G26,
  G27,
  G28,
  G29,
  G30,
  G31,
  G32,
  G33,
  G34,
  G35,
  G36,
  G37,
  G38,
  G39,
  G40,
  G41,
  G42,
  G43,
  G44,
  G45,
  G46,
};

export default images;
