/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import images from "../../assets/images";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import "./index.css";

const Navbar = () => {
  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const onMain = () => {
    history("/");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { t } = useTranslation("translations");

  const changeLanguage = (lng) => {
    if (lng === localStorage.getItem("lang")) return;
    localStorage.setItem("lang", lng);
    window.location.reload();
  };

  const onLanguangeEn = (e) => {
    changeLanguage("en");
  };
  const onLanguangeId = (e) => {
    changeLanguage("id");
  };
  const onLanguangeMs = (e) => {
    changeLanguage("ms");
  };
  const onLanguangeZh = (e) => {
    changeLanguage("ch");
  };

  return (
    <div className="navbar-custom">
      <div className="contain">
        <div className={`${isScrolled ? "scroll" : "unscroll"}`}>
          <nav className="major">
            <div className="brand">
              <img
                src={images.logo}
                alt="Brand Logo"
                type="button"
                onClick={onMain}
              />
            </div>
            <div className="menu-toggle" onClick={toggleMenu}>
              {isOpen ? (
                <FaTimes size={30} style={{ color: "#ff6501" }} />
              ) : (
                <FaBars size={30} style={{ color: "#ff6501" }} />
              )}
            </div>
            <div className="menu">
              {isOpen ? (
                <>
                  <div className="list active">
                    <div className="open">
                      <div className="top">
                        <div className="left">
                          <a href="/">{t("nav.home")}</a>
                          <a href="/all-package">{t("nav.package")}</a>
                          <a href="#contact">{t("nav.contact")}</a>
                          <a
                            style={{ marginTop: "20px" }}
                            onClick={onLanguangeId}
                          >
                            Indonesia
                          </a>
                          <a onClick={onLanguangeEn}>English</a>
                          <a onClick={onLanguangeMs}>Malaysia</a>
                          <a onClick={onLanguangeZh}>Chinese</a>
                        </div>
                        <div className="right">
                          <a href="/east-java-2D1N">
                            {t("nav.east-java.2D1N")}
                          </a>
                          <a href="/east-java-3D2N">
                            {t("nav.east-java.3D2N")}
                          </a>
                          <a href="/east-java-4D3N">
                            {t("nav.east-java.4D3N")}
                          </a>
                          <a href="/east-java-5D4N">
                            {t("nav.east-java.5D4N")}
                          </a>
                          <a href="/east-java-6D5N">
                            {t("nav.east-java.6D5N")}
                          </a>
                          <a href="/central-java">{t("nav.central-java")}</a>
                          <a href="/lombok">{t("nav.lombok")}</a>
                          <a href="/semeru">{t("nav.semeru")}</a>
                          <a href="/east-center-java">{t("nav.ec-java")}</a>
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="center">
                        <div className="left">
                          <FaWhatsapp className="icon" />
                          <a href="#contact" onClick={toggleMenu}>
                            {t("button.wa")}
                          </a>
                        </div>
                        <div className="right">
                          <MdOutlineMail className="icon" />
                          <a href="#contact" onClick={toggleMenu}>
                            {t("button.email")}
                          </a>
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="bottom">
                        <FaInstagram />
                        <FaFacebookSquare />
                        <FaTiktok />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <ul className="list">
                  <li className="close">
                    <a href="/">{t("nav.home")}</a>
                  </li>
                  <li className="close">
                    <div className="dropdown-custom">
                      <button className="dropdown-toggle-custom">
                        {t("nav.package")}
                      </button>
                      <div className="dropdown-menu-custom">
                        <a href="/east-java-2D1N">{t("nav.east-java.2D1N")}</a>
                        <a href="/east-java-3D2N">{t("nav.east-java.3D2N")}</a>
                        <a href="/east-java-4D3N">{t("nav.east-java.4D3N")}</a>
                        <a href="/east-java-5D4N">{t("nav.east-java.5D4N")}</a>
                        <a href="/east-java-6D5N">{t("nav.east-java.6D5N")}</a>
                        <a href="/central-java">{t("nav.central-java")}</a>
                        <a href="/lombok">{t("nav.lombok")}</a>
                        <a href="/semeru">{t("nav.semeru")}</a>
                        <a href="/east-center-java">{t("nav.ec-java")}</a>
                      </div>
                    </div>
                  </li>
                  <li className="close">
                    <a href="#contact">{t("nav.contact")}</a>
                  </li>
                  <li className="close">
                    <div className="dropdown-custom">
                      <button className="dropdown-toggle-custom">
                        {t("nav.languange")}
                      </button>
                      <div className="dropdown-menu-custom">
                        <a onClick={onLanguangeId}>Indonesia</a>
                        <a onClick={onLanguangeEn}>English</a>
                        <a onClick={onLanguangeMs}>Malaysia</a>
                        <a onClick={onLanguangeZh}>Chinese</a>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
